import React, {useEffect, useState}  from "react"
import dateFormat from 'dateformat';
import moment from 'moment';
import {Container,Navbar,Nav} from 'react-bootstrap';
import $ from "jquery";
import HTMLReactParser from 'html-react-parser';

import FullCalendar from "@fullcalendar/react";
import { Calendar, Tooltip } from '@fullcalendar/core';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

import VideoModule from "./video-module"

import {config} from '../../components/Common/constant';
import { GET_ARTICLE, SELLER_DETAILS_EMAIL, GET_CSM_NAME_PROPERTY } from "../../queries/common_use_query";
import { useContainerContext } from "../../store/ContainerContext";
import { handleLogin, isBrowser, setUser, getUser, isLoggedIn, resetPassword } from "../../services/auth"
import Title_Block from  "./title_block";
import TimeTrackerComp from "./time_tracker"


const Property_Lauch_DateTime = (props) => {
    var strapi_url = process.env.GATSBY_STRAPI_SRC;
    const context = useContainerContext();
    const { property_data, property_date_time, property_launch_date_time } = context.state;
    const { PropertyDateTimeDispatch, PropertyLaunchDateTimeDispatch } = context.actions;
    const calendarComponentRef = React.createRef();

    const [ article_data, setArticleData ] = useState('');
    const [ playVideo, setPlayVideo ] = useState(false);
    const { loading, error, data } = GET_ARTICLE('property-launch-date-and-time');

    const { data:csm_data } = GET_CSM_NAME_PROPERTY(property_launch_date_time.CSM);

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    useEffect(()=>{
        //console.log("csm_data", csm_data);
        if(csm_data && csm_data.customerServiceManagers.length > 0)
        {
            PropertyLaunchDateTimeDispatch({...property_launch_date_time, CSM_Name:csm_data.customerServiceManagers[0].Name})
        }
        //PropertyLaunchDateTimeDispatch({...property_launch_date_time})
    }, [csm_data]);

    useEffect(() => {
        //console.log("property_launch_date_time 1", property_launch_date_time);
        if(property_launch_date_time && property_launch_date_time.LaunchStart){
            var property_launch_date = dateFormat(property_launch_date_time.LaunchStart, "yyyy-mm-dd");
            var property_launch_time = dateFormat(property_launch_date_time.LaunchStart, "h:MMtt");
            var csm_initials_split = property_launch_date_time.CSM.split('.');
            var csm_initials = csm_initials_split[0].substr(0,1)+''+csm_initials_split[1].substr(0,1);

            setTimeout( function(){
                var check_selected_date = $("#property_launch_cal .fc-scrollgrid-sync-table").find('.fc-daygrid-day.active_cls[data-date=' + property_launch_date + ']');
                //console.log("property_launch_date", check_selected_date.length);

                if(check_selected_date.length === 1){
                    $("#property_launch_cal .fc-scrollgrid-sync-table").find('.fc-daygrid-day.active_cls').addClass('light_red').removeClass('actv_cls2');
                    $("#property_launch_cal .fc-scrollgrid-sync-table").find('.fc-daygrid-day.active_cls[data-date=' + property_launch_date + ']').removeClass('light_red').addClass('actv_cls2');
                    $(".fc-event-time:contains('"+property_launch_time+"')", '#property_launch_cal .fc-scrollgrid-sync-table .fc-daygrid-day.active_cls[data-date=' + property_launch_date + ']').next(".fc-event-title:contains('"+csm_initials+"')").parent().parent('.fc-daygrid-event-harness').addClass('actv');
                }
                
            },1000);

            //console.log('property_launch_date', property_launch_date, property_launch_time);
        }
    }, [property_launch_date_time]);
  
      
    if(isBrowser()){
        setTimeout( function(){
            $("#property_launch_cal .fc-daygrid-event-harness").parent().parent().parent(".fc-daygrid-day").addClass('active_cls');
        }, 500);

        $('body').on('click', '#property_launch_cal button.fc-prev-button', function() {
            //do something
            setTimeout( function(){
                $("#property_launch_cal .fc-daygrid-event-harness").parent().parent().parent(".fc-daygrid-day").addClass('active_cls');
            }, 300);
            });
            
        $('body').on('click', '#property_launch_cal button.fc-next-button', function() {
        //do something
            //alert("test2");
            setTimeout( function(){
                $("#property_launch_cal .fc-daygrid-event-harness").parent().parent().parent(".fc-daygrid-day").addClass('active_cls');
            }, 300);
        });
    }

    
    const handleEventClick = (events) => {
        //console.log("events", events.el);
        //console.log("events2", events.event._def);
        $('#property_launch_cal .fc-daygrid-event-harness').removeClass('actv');
        $(events.el.parentElement).addClass('actv');
        $("#property_launch_cal .fc-scrollgrid-sync-table").find('.fc-daygrid-day.active_cls').addClass('light_red').removeClass('actv_cls2');
        $(events.el.parentElement.parentElement.parentElement.parentElement).removeClass('light_red').addClass('actv_cls2');
        //$(events.el.parentElement.parentElement.parentElement.parentElement).append('<div class="tooltipText">'+events.event._def.extendedProps.description+'</div>');

        PropertyLaunchDateTimeDispatch(events.event._def.extendedProps);
        
    }

    //console.log("propertyDateTime 1", property_launch_date_time);

    var initial_date = props.propertyDateTime && props.propertyDateTime.length > 0 ? props.propertyDateTime[0].start : new Date();

    //console.log("initial_date 1", initial_date);
  
    return <>
        <TimeTrackerComp TabName={'Schedule'} />

       <div className="section-wrapper calendar_cnt">
          <Container>
            <Title_Block Title={props.Title ? props.Title : article_data && article_data.Title} Type={props.Type} PreviousLink={''} NextLink={''} NextPage={''} PrevPage={''} instruct={true}/>

            {article_data && article_data.Content && <p className="fig-content mb_cnt font_20">{HTMLReactParser(article_data.Content)}</p>}

                    <div className={`calendar-wrapper ${props.Type === "preview" && props.present_type === "custom_client" ? "preview" : ''}`} id="property_launch_cal">
                        {
                            props.propertyDateTime &&  props.propertyDateTime.length > 0 ?
                            <FullCalendar
                                selectable={true}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                ref={calendarComponentRef}
                                weekends={true}                    
                                events={props.propertyDateTime}
                                datesRender={props.propertyDateTime}
                                eventClick={props.Type === "preview" && props.present_type === "custom_client" ? false : handleEventClick}
                                displayEventTime={true}
                                showNonCurrentDates={false}
                                initialDate={props.propertyDateTime[0].start ? props.propertyDateTime[0].start : ''}
                                eventTimeFormat={{hour: "numeric",minute: "2-digit",meridiem: "short"}}
                                height={"720px"}                                
                                //eventClick={(arg, e) => chooseDate(arg, e)}
                            /> : 
                            <FullCalendar
                                selectable={true}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                ref={calendarComponentRef}
                                weekends={true}                    
                                displayEventTime={true}
                                showNonCurrentDates={false}
                                eventTimeFormat={{hour: "numeric",minute: "2-digit",meridiem: "short"}}
                                height={"720px"}                                
                                //eventClick={(arg, e) => chooseDate(arg, e)}
                            />
                        }                            
                    </div>

                {/* {
                    props.Type === "preview" && props.present_type === "custom_client" ? (
                        <div className="calendar-wrapper preview" id="property_launch_cal">
                            {
                                props.propertyDateTime &&  props.propertyDateTime.length > 0 && 
                                <FullCalendar
                                    selectable={false}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    ref={calendarComponentRef}
                                    weekends={true}                    
                                    events={props.propertyDateTime}                                
                                    displayEventTime={true}
                                    showNonCurrentDates={false}
                                    initialDate={props.propertyDateTime[0].start}
                                    eventTimeFormat={{hour: "numeric",minute: "2-digit",meridiem: "short"}}
                                    height={"720px"}
                                />
                            }
                            
                        </div>
                    ) : (
                        <div className="calendar-wrapper" id="property_launch_cal">
                            {
                                props.propertyDateTime &&  props.propertyDateTime.length > 0 &&
                                <FullCalendar
                                    selectable={true}
                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                    ref={calendarComponentRef}
                                    weekends={true}                    
                                    events={props.propertyDateTime}
                                    datesRender={props.propertyDateTime}
                                    eventClick={props.Type === "preview" && props.present_type === "custom_client" ? false : handleEventClick}
                                    displayEventTime={true}
                                    showNonCurrentDates={false}
                                    initialDate={props.propertyDateTime[0].start ? props.propertyDateTime[0].start : ''}
                                    eventTimeFormat={{hour: "numeric",minute: "2-digit",meridiem: "short"}}
                                    height={"720px"}
                                    
                                    //eventClick={(arg, e) => chooseDate(arg, e)}
                                />
                            }                            
                        </div>
                    )
                } */}

            {/* calendar-wrapper */}
            </Container>
        </div>
    </>
}

export default Property_Lauch_DateTime
